import React from 'react';

export function SvgMenu(props) {
	const { type, fill } = props;

	switch (type) {
		case 'wallet':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M21.1 8.004C21.045 8 20.984 8 20.92 8h-2.525c-2.068 0-3.837 1.628-3.837 3.75s1.77 3.75 3.837 3.75h2.525c.064 0 .125 0 .182-.004a1.755 1.755 0 001.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 00-1.645-1.628zm-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1z'
						fill='#fff'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M20.918 17a.22.22 0 01.221.278c-.2.712-.519 1.32-1.03 1.83-.749.75-1.698 1.081-2.87 1.239-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87C2 15.099 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.511.512.83 1.119 1.03 1.831a.22.22 0 01-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25h2.524zM5.75 7a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-4z'
						fill='#fff'
					/>
				</svg>
			);
		case 'import':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464zM12 7.25a.75.75 0 01.75.75v6.19l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72V8a.75.75 0 01.75-.75z'
						fill='#fff'
					/>
				</svg>
			);
		case 'backup':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm3.935-16.408a.75.75 0 01.467.694v2.715a.75.75 0 01-.75.75H13a.75.75 0 01-.537-1.274l.762-.78a4.17 4.17 0 00-4.224 1.089c-1.668 1.707-1.668 4.483 0 6.19a4.169 4.169 0 005.998 0 4.394 4.394 0 001.208-2.472c.058-.418.39-.77.812-.77.406 0 .742.325.703.729a5.897 5.897 0 01-1.65 3.562 5.669 5.669 0 01-8.144 0c-2.237-2.29-2.237-5.997 0-8.287a5.666 5.666 0 016.437-1.208l.75-.768a.75.75 0 01.82-.17z'
						fill='#fff'
					/>
				</svg>
			);
		case 'security':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.25 10.055V8a6.75 6.75 0 0113.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121.53-.531 1.256-.741 2.371-.824zM6.75 8a5.25 5.25 0 0110.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004V8zM14 16a2 2 0 11-4 0 2 2 0 014 0z'
						fill='#fff'
					/>
				</svg>
			);
		case 'bell':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M8.352 20.242A4.63 4.63 0 0012 22a4.63 4.63 0 003.648-1.758 27.158 27.158 0 01-7.296 0zM18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 01-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723c.452-.703.693-1.529.693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7z'
						fill='#fff'
					/>
				</svg>
			);
		case 'rpc':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464zm10.024 2.982a.75.75 0 01.53.918l-2.588 9.66a.75.75 0 01-1.449-.389l2.588-9.659a.75.75 0 01.92-.53zM14.97 8.47a.75.75 0 011.06 0l.209.208c.635.635 1.165 1.165 1.529 1.642.384.504.654 1.036.654 1.68 0 .644-.27 1.176-.654 1.68-.364.477-.894 1.007-1.53 1.642l-.208.208a.75.75 0 11-1.06-1.06l.171-.172c.682-.682 1.139-1.14 1.434-1.528.283-.37.347-.586.347-.77 0-.184-.064-.4-.347-.77-.295-.387-.752-.846-1.434-1.528l-.171-.172a.75.75 0 010-1.06zm-7 0a.75.75 0 011.06 1.06l-.171.172c-.682.682-1.138 1.14-1.434 1.528-.283.37-.346.586-.346.77 0 .184.063.4.346.77.296.387.752.846 1.434 1.528l.172.172a.75.75 0 11-1.061 1.06l-.208-.208c-.636-.635-1.166-1.165-1.53-1.642-.384-.504-.653-1.036-.653-1.68 0-.644.27-1.176.653-1.68.364-.477.894-1.007 1.53-1.642l.208-.208z'
						fill='#fff'
					/>
				</svg>
			);
		case 'connect':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 8.035c-2.697 0-4.884 2.151-4.884 4.806a4.75 4.75 0 001.43 3.398.679.679 0 010 .97.706.706 0 01-.986 0 6.113 6.113 0 01-1.84-4.368c0-3.413 2.812-6.18 6.28-6.18 3.468 0 6.28 2.767 6.28 6.18a6.113 6.113 0 01-1.84 4.369.706.706 0 01-.987 0 .679.679 0 010-.971 4.75 4.75 0 001.43-3.398c0-2.655-2.186-4.806-4.883-4.806z'
						fill='#fff'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 4.373c-4.752 0-8.605 3.791-8.605 8.468 0 2.338.963 4.454 2.52 5.987a.679.679 0 010 .97.706.706 0 01-.986 0A9.734 9.734 0 012 12.842C2 7.406 6.477 3 12 3s10 4.406 10 9.84a9.734 9.734 0 01-2.929 6.959.706.706 0 01-.987 0 .679.679 0 010-.971 8.372 8.372 0 002.52-5.987c0-4.677-3.852-8.468-8.604-8.468z'
						fill='#fff'
					/>
					<path
						d='M10.31 17.344c.767-.876 1.151-1.314 1.625-1.342.043-.003.087-.003.13 0 .474.028.858.466 1.625 1.342 1.67 1.906 2.505 2.858 2.271 3.68-.02.071-.045.14-.074.206-.344.77-1.525.77-3.887.77s-3.543 0-3.887-.77a1.527 1.527 0 01-.074-.206c-.234-.822.6-1.774 2.27-3.68zM14.5 12.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z'
						fill='#fff'
					/>
				</svg>
			);
		case 'about':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zm0-4.25a.75.75 0 00.75-.75v-6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75zM12 7a1 1 0 110 2 1 1 0 010-2z'
						fill='#fff'
					/>
				</svg>
			);
		case 'success':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M22 11.5a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0z'
						fill='#02FEA4'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M16.757 8.24c.316.328.325.87.02 1.21l-4.651 5.2a1.008 1.008 0 01-1.55-.03L8.203 11.77a.904.904 0 01.06-1.21.755.755 0 011.122.067l1.993 2.395 4.256-4.76a.754.754 0 011.123-.021z'
						fill='#090D15'
					/>
				</svg>
			);
		case 'error':
			return (
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<g clipPath='url(#clip0_805_10618)'>
						<path
							d='M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z'
							fill='#F93B68'
						/>
					</g>
					<defs>
						<clipPath id='clip0_805_10618'>
							<rect width='24' height='24' fill='white' />
						</clipPath>
					</defs>
				</svg>
			);
		case 'send':
			return (
				<svg
					width={25}
					height={24}
					viewBox='0 0 25 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<g clipPath='url(#clip0_805_10554)'>
						<path
							d='M10.2891 22.5524L22.1154 6.73907L2.50749 9.07423L5.52619 14.3201L17.6226 9.33295L7.25544 17.3152L10.2891 22.5524Z'
							fill='#F4C714'
						/>
					</g>
					<defs>
						<clipPath>
							<rect
								width={24}
								height={24}
								fill='white'
								transform='translate(0.5)'
							/>
						</clipPath>
					</defs>
				</svg>
			);
		case 'receive':
			return (
				<svg
					width={25}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<g clipPath='url(#prefix__clip0_805_10559)'>
						<path
							d='M19.5 9h-4V3h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z'
							fill='#F4C714'
						/>
					</g>
					<defs>
						<clipPath id='prefix__clip0_805_10559'>
							<path fill='#fff' transform='translate(.5)' d='M0 0h24v24H0z' />
						</clipPath>
					</defs>
				</svg>
			);
		case 'buy':
			return (
				<svg
					width={25}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<g clipPath='url(#prefix__clip0_805_10564)'>
						<path
							d='M17.71 9l-4.38-6.56a.993.993 0 00-.83-.42c-.32 0-.64.14-.83.43L7.29 9H2.5c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27.03-.27c0-.55-.45-1-1-1h-4.79zM9.5 9l3-4.4 3 4.4h-6zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'
							fill='#F4C714'
						/>
					</g>
					<defs>
						<clipPath id='prefix__clip0_805_10564'>
							<path fill='#fff' transform='translate(.5)' d='M0 0h24v24H0z' />
						</clipPath>
					</defs>
				</svg>
			);
		case 'home':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<g clipPath='url(#prefix__clip0_1001_1102)'>
						<path
							d='M13 13v8h8v-8h-8zM3 21h8v-8H3v8zM3 3v8h8V3H3zm13.66-1.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65z'
							fill={fill ? fill : 'var(--light)'}
						/>
					</g>
					<defs>
						<clipPath id='prefix__clip0_1001_1102'>
							<path fill={fill ? fill : 'var(--light)'} d='M0 0h24v24H0z' />
						</clipPath>
					</defs>
				</svg>
			);
		case 'buy-crypto':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<g clipPath='url(#prefix__clip0_1001_1105)'>
						<path
							d='M18 4H6C3.79 4 2 5.79 2 8v8c0 2.21 1.79 4 4 4h12c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-1.86 9.77c-.24.2-.57.28-.88.2L4.15 11.25C4.45 10.52 5.16 10 6 10h12c.67 0 1.26.34 1.63.84l-3.49 2.93zM6 6h12c1.1 0 2 .9 2 2v.55c-.59-.34-1.27-.55-2-.55H6c-.73 0-1.41.21-2 .55V8c0-1.1.9-2 2-2z'
							fill={fill ? fill : 'var(--light)'}
						/>
					</g>
					<defs>
						<clipPath id='prefix__clip0_1001_1105'>
							<path fill={fill ? fill : 'var(--light)'} d='M0 0h24v24H0z' />
						</clipPath>
					</defs>
				</svg>
			);
		case 'swap':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M10.2 7.8a5.4 5.4 0 115.916 6.561M12.6 16.2a5.4 5.4 0 11-10.8 0 5.4 5.4 0 0110.8 0zM6.6 6L9 3.6 6.6 1.2M1.8 8.4V6.8A3.2 3.2 0 015 3.6h2.4m10 14.4L15 20.4l2.4 2.4m4.8-7.2v1.6a3.2 3.2 0 01-3.2 3.2h-2.4'
						stroke={fill ? fill : 'var(--light)'}
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<circle
						cx={7.5}
						cy={16.5}
						r={6.5}
						fill={fill ? fill : 'var(--light)'}
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M15.5 16a6.5 6.5 0 10-6.483-6.98 6.5 6.5 0 015.966 6.96c.17.013.343.02.517.02z'
						fill={fill ? fill : 'var(--light)'}
					/>
				</svg>
			);
		case 'settings':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<g clipPath='url(#prefix__clip0_1001_1117)'>
						<path
							d='M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 00.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 00-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94 0 .31.02.64.07.94l-2.03 1.58a.49.49 0 00-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6A3.61 3.61 0 018.4 12c0-1.98 1.62-3.6 3.6-3.6s3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z'
							fill={fill ? fill : 'var(--light)'}
						/>
					</g>
					<defs>
						<clipPath id='prefix__clip0_1001_1117'>
							<path fill={fill ? fill : 'var(--light)'} d='M0 0h24v24H0z' />
						</clipPath>
					</defs>
				</svg>
			);
		case 'phrase':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535zM18.75 16a.75.75 0 01-.75.75H6a.75.75 0 010-1.5h12a.75.75 0 01.75.75zM18 12.75a.75.75 0 000-1.5H6a.75.75 0 000 1.5h12zM18.75 8a.75.75 0 01-.75.75H6a.75.75 0 010-1.5h12a.75.75 0 01.75.75z'
						fill='#fff'
					/>
				</svg>
			);
		case 'key':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M18.977 14.79a6.907 6.907 0 10-11.573-3.159c.095.369.01.768-.258 1.037L3.433 16.38a1.48 1.48 0 00-.424 1.21l.232 2.089c.025.223.125.43.283.589l.208.208a.987.987 0 00.589.283l2.089.232a1.48 1.48 0 001.21-.424l.71-.71-1.747-1.728a.75.75 0 111.055-1.066l1.752 1.733 1.942-1.942c.27-.27.668-.353 1.037-.258a6.904 6.904 0 006.608-1.806zm-6.391-6.204a2 2 0 112.828 2.828 2 2 0 01-2.828-2.828z'
						fill='#fff'
					/>
				</svg>
			);
		case 'basket':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M3 6.524c0-.395.327-.714.73-.714h4.788c.006-.842.098-1.995.932-2.793A3.68 3.68 0 0112 2a3.68 3.68 0 012.55 1.017c.834.798.926 1.951.932 2.793h4.788c.403 0 .73.32.73.714a.722.722 0 01-.73.714H3.73A.722.722 0 013 6.524zM11.607 22h.787c2.707 0 4.06 0 4.94-.863.881-.863.971-2.28 1.151-5.111l.26-4.08c.098-1.537.146-2.306-.295-2.792-.442-.487-1.188-.487-2.679-.487H8.23c-1.491 0-2.237 0-2.679.487-.441.486-.392 1.255-.295 2.791l.26 4.08c.18 2.833.27 4.249 1.15 5.112C7.545 22 8.9 22 11.607 22z'
						fill='#98A1C0'
					/>
				</svg>
			);
		default:
			break;
	}
}
